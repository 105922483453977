<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-21 14:54:37
 * @FilePath: /mediatom-web/src/views/accountInfo/account/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="accountContainers">
    <a-row :gutter="24" style="width: 500px; display: inline-block">
      <a-col>
        <a-card :bordered="false">
          <div style="text-align: left;margin-bottom:20px">
            <a-radio-group v-model="isAccountOrPassword" style="text-align: left; margin-bottom: 15px">
              <a-radio-button value="account" class="left-radius">基本信息 </a-radio-button>
              <a-radio-button value="password" :class="{'middle-radius': +isAdmin === 1 || +isAdmin === 2, 'right-radius': !(+isAdmin === 1 || +isAdmin === 2)}">修改密码</a-radio-button>
              <a-radio-button value="resetkey" class="right-radius" v-if="+isAdmin === 1 || +isAdmin === 2">Key</a-radio-button>
            </a-radio-group>
          </div>
          <AccountForm v-show="isAccountOrPassword === 'account'" ref="AccountForm"></AccountForm>
          <passwordForm v-show="isAccountOrPassword === 'password'" ref="passwordForm"></passwordForm>
          <ResetApiKey v-show="isAccountOrPassword === 'resetkey'" ref="resetkeyForm"/>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import AccountForm from './components/accountForm'
import passwordForm from './components/passwordForm'
import ResetApiKey from './components/ResetApiKey'
import { mapState } from 'vuex'
export default {
  name: 'AccountInfo',
  components: { passwordForm, AccountForm, ResetApiKey },
  data () {
    return {
      isAccountOrPassword: 'account'
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin
    })
  }
}
</script>
<style lang="less" scoped>
::v-deep.ant-radio-button-wrapper-checked {
  color: #456bff;
  background: fade(@primary-color, 15%);
  border: 1px solid #456bff;
}
.left-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.right-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accountContainers {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 0px 10px;
  border-radius: 10px;
  padding: 30px 70px 70px;
  height: 800px;
  display: inline-block;
  width: calc(100% - 20px);
}
</style>
